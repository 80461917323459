import { ReactNode, useSyncExternalStore } from 'react';
import { isBrowser } from '~/constants';

function subscribe() {
  return () => {};
}

type Props = {
  children(): ReactNode;
  fallback?: ReactNode;
};

export function ClientOnly({ children, fallback = null }: Props) {
  const hydrated = useSyncExternalStore(
    subscribe,
    () => true,
    () => false,
  );

  return hydrated && isBrowser ? <>{children()}</> : <>{fallback}</>;
}
